// see: https://stackoverflow.com/questions/4391575/how-to-find-the-size-of-localstorage

export type StorageSize<Fields extends string = string> = {
  total: number
  keys: Partial<Record<Fields, number>>
}

export function getObjectSize<Fields extends string = string>(
  source: Partial<Record<Fields, any>>,
  verbose: boolean = false,
): StorageSize<Fields> {
  let totalBytes = 0
  const storageSize = {
    total: 0,
    keys: {} as Record<Fields, number>,
  }

  for (const [key, sourceValue] of Object.entries(source)) {
    if (!source.hasOwnProperty(key)) {
      continue
    }

    const value: string =
      typeof sourceValue === 'string' ? sourceValue : JSON.stringify(sourceValue)

    // Value is multiplied by 2 due to data being stored in `utf-16` format, which requires twice the space.
    const bytes = (key.length + value.length) * 2
    if (verbose) {
      console.log(
        `[${key}] --> ${key.length + value.length} chars for ${(bytes / 1024).toFixed(2)} KB`,
      )
    }

    storageSize.keys[key] = bytes
    totalBytes += bytes
  }

  storageSize.total = totalBytes

  if (verbose) {
    console.log(`Total --> ${totalBytes.toFixed(2)} B`)
    console.log(`Total --> ${(totalBytes / 1024).toFixed(2)} KB`)
    console.log(`Total --> ${(totalBytes / 1024 / 1024).toFixed(4)} MB`)
  }

  return storageSize
}
