export type Environment =
  | 'dev'
  | 'qa' // former `demo`
  // | 'next' // dismissed
  | 'local'
  | 'test-a'
  | 'test-b'
  | 'unknown'
  | 'preview'
  | 'production'

/**
 * Get the Sentry environment based on the URL. The Sentry environment can be set only during the init phase.
 * ATTENTION: This "environment" has nothing to do with the environment atom of the UI that comes
 * from the config.json file. This is helpful for
 * - allowing QA to have a 1:1 relation between the URL and the Sentry environment
 * - allowing QA to detect if there are errors in a "unknown" (means not managed yet) environment
 * - avoiding the app to wait for the config.json to be ready to start logging
 * That's why this module is private, the rest of the app must base on the config.json file
 */
export function getSentryEnvironment(url: string): Environment {
  if (isQa(url)) return 'qa'
  if (isDev(url)) return 'dev'
  if (isLocal(url)) return 'local'
  if (isTestA(url)) return 'test-a'
  if (isTestB(url)) return 'test-b'
  if (isPreview(url)) return 'preview'
  if (isProduction(url)) return 'production'

  return 'unknown'
}

function isLocal(url: string) {
  return url.includes('localhost')
}

function isTestA(url: string) {
  return url.includes('test-a.')
}

function isTestB(url: string) {
  return url.includes('test-b.')
}

function isPreview(url: string) {
  // includes the temporary v3-preview.deyrqhtj9y823.amplifyapp.com
  return url.includes('preview.')
}

function isQa(url: string) {
  // the `demo.` URL has been dismissed in favor of `qa.`
  return url.includes('qa.')
}

// V3 public beta is at beta.routemanager.workwave.com
function isProduction(url: string) {
  return url.includes('beta.') || url.includes('//routemanager.workwave.com')
}

// https://dev.routemanager.workwave.com/
function isDev(url: string) {
  if (url.includes('dev.')) {
    return true
  }

  // include https://pr-804.deyrqhtj9y823.amplifyapp.com/#/login
  if (url.includes('amplifyapp.com') && url.includes('pr-')) {
    return true
  }

  return false
}
