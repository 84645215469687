import {
  JournalTargetComposer,
  JournalTarget,
  AddEntryNext,
  GetEntriesNext,
  AddEntryInfo,
  ClearEntriesNext,
} from '../typings'

export const composeMiddlewares = (middlewares: JournalTargetComposer[]): JournalTarget => {
  const emptyResult = []

  const finalAddEntry: AddEntryNext = (_: AddEntryInfo) => Promise.resolve()
  const finalGetEntries: GetEntriesNext = () => Promise.resolve(emptyResult)
  const finalClearEntries: ClearEntriesNext = () => Promise.resolve()

  let composed: JournalTarget = {
    addEntry: finalAddEntry,
    getEntries: finalGetEntries,
    clearEntries: finalClearEntries,
  }

  for (const middleware of middlewares) {
    composed = {
      addEntry: middleware.addEntry(composed.addEntry),
      getEntries: middleware.getEntries(composed.getEntries),
      clearEntries: middleware.clearEntries(composed.clearEntries),
    }
  }

  return composed
}
