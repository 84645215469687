import { JournalOptions, CreateJournalTarget, JournalTargetComposer } from '../typings'

export function initializeJournalTargets<ExtraApi = any>(
  opt: JournalOptions<ExtraApi>,
  middlewares: CreateJournalTarget<ExtraApi>[],
): Promise<JournalTargetComposer<ExtraApi>[]> {
  return new Promise(async (resolve, reject) => {
    const targets: JournalTargetComposer<ExtraApi>[] = []

    const uniqueNames = new Set<string>()

    for (const middleware of middlewares) {
      const target: JournalTargetComposer<ExtraApi> = await middleware(opt)

      if (uniqueNames.has(target.name)) {
        reject(
          new Error(
            `Journal doesn't support multiple targets with the same name. [${target.name}] used more than once`,
          ),
        )
      }
      uniqueNames.add(target.name)

      targets.push(target)
    }

    resolve(targets)
  })
}
