import type {
  Journal,
  JournalEntry,
  AddEntryInfo,
  AddEntryResult,
  AddJournalEntryParams,
} from '@/journal'

import { createJournal } from '@/journal'
import { createJournalTargets } from './utils/createJournalTargets'

export type AddEntry = (
  v: string,
  p?: AddJournalEntryParams,
  mode?: JournalEntry['severity'],
) => Promise<AddEntryResult>

export type SkipEntry = (entry: AddEntryInfo) => boolean

let waitForJournal: Promise<Journal> | undefined

const getJournal = (): Promise<Journal> => {
  if (!waitForJournal) {
    waitForJournal = createJournal({
      disabled: false,
      name: 'store-journal',
      level: 'normal',
      // prefix: '',
      targets: createJournalTargets(),
    })
  }

  return waitForJournal
}

const processParams = (
  params: AddJournalEntryParams = {},
  tags: string[],
): AddJournalEntryParams => {
  return {
    ...params,
    tags: [...tags, ...(params.tags ?? [])],
  }
}

const createAddEntry =
  (tags: string[]): AddEntry =>
  async (value, params, mode: JournalEntry['severity'] = 'log') => {
    const journal = await getJournal()

    switch (mode) {
      case 'log':
        return await journal.addEntry(value, processParams(params, tags))

      case 'warn':
        return await journal.addWarning(value, processParams(params, tags))

      case 'error':
        return await journal.addError(value, processParams(params, tags))
    }
  }

export const journal = {
  core: {
    getJournal,

    setJournalPrefix: async (prefix: string) => {
      const journal = await getJournal()
      journal.prefix = prefix
    },
  },

  // non-user triggered UI logs
  ui: createAddEntry(['ui']),

  // user triggered UI logs
  user: createAddEntry(['user']),

  // thunks or reducers logs
  store: createAddEntry(['store']),

  // recoil atoms logs
  atoms: createAddEntry(['atoms']),

  // mostly RPC sagas logs
  domain: createAddEntry(['domain']),

  // REST or Socket requests logs
  remote: createAddEntry(['remote']),

  // all derived actions like push notification, timeout for GPS changetset, autocommit timer, etc...
  external: createAddEntry(['external']),

  // all internal implementation of the worker: initialization, socket maintenanc, etc,
  worker: createAddEntry(['worker']),

  // all internal implementation of the UI: initialization, etc,
  main: createAddEntry(['main']),

  // log pendo events,
  pendo: createAddEntry(['pendo']),
} as const

export type JournalHelper = typeof journal
