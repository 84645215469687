export function generateDevicesOptions(): uui.domain.ui.list.ListOptions {
  return {
    search: {
      query: '',
      fields: ['label', 'id'],
      operator: 'or',
      exactMatch: false,
    },
    filter: [],
    sort: [
      {
        field: 'label',
        direction: 'asc',
      },
    ],
    group: [],
  }
}
