import { getObjectSize } from '../../utils/getObjectSize'
import { JournalOptions, AddEntryInfo } from '../../typings'
import { LocalStorageTargetOptions } from './typings'
import { reduceEntrySize } from './reduceEntrySize'

export const addEntry = (
  separator: string,
  options: LocalStorageTargetOptions,
  journalOptions: JournalOptions,
  entryInfo: AddEntryInfo,
) => {
  const { entry, size } = entryInfo

  const canPrint =
    journalOptions.level === 'verbose' ||
    (journalOptions.level === 'normal' && entry.severity !== 'log')

  if (canPrint) {
    let entryAsString = JSON.stringify(entry)
    let storedString = localStorage.getItem(journalOptions.name) || ''

    const lsSize = getObjectSize(localStorage)

    const entrySizeKb = size.total / 1024
    const journalSizeKb = (lsSize.keys[journalOptions.name] || 0) / 1024

    // the max sizee cannot exceed ~5MB for the Local Storage
    const maxAvailableKb = 5120 - lsSize.total - journalSizeKb
    const maxSizeKb =
      maxAvailableKb < options.maxLocalStorageSize ? maxAvailableKb : options.maxLocalStorageSize

    if (entrySizeKb > maxSizeKb) {
      const clone = reduceEntrySize(entryInfo, maxAvailableKb)
      entryAsString = JSON.stringify(clone)
    }

    let totalKb = entrySizeKb + journalSizeKb

    if (totalKb > maxSizeKb) {
      const entries = storedString.split(separator)
      const entriesSize = getObjectSize(entries)

      removeEntries: for (let idx = 0; idx < entries.length; idx++) {
        entries.shift()

        const elementSize = entriesSize.keys[idx] || 0
        totalKb -= elementSize

        if (totalKb < maxSizeKb) {
          break removeEntries
        }
      }

      storedString = entries.join(separator)
    }

    if (storedString.trim().length === 0) {
      localStorage.setItem(journalOptions.name, entryAsString)
    } else {
      localStorage.setItem(journalOptions.name, `${storedString}${separator}${entryAsString}`)
    }
  }
}
