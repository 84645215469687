import {
  JournalTargetComposer,
  CreateJournalTarget,
  JournalOptions,
  JournalTargetOptions,
  PartialJournalTargetOptions,
  CreateJournalTargetCreator,
  CreateJournalTargetApi,
  JournalTargetApi,
} from '../../typings'

type RequiredJournalTargetOptions<
  Options extends PartialJournalTargetOptions = PartialJournalTargetOptions,
> = JournalTargetOptions & Options

type RequiredJournalTargetApi<ExtraApi = void> = JournalTargetApi<ExtraApi> & {
  getApi: () => ExtraApi
}

const defaultJournalTargetOptions: JournalTargetOptions = {
  disabled: false,
  transformEntry: entry => entry,
  skipEntry: () => false,
  blockEntry: () => false,
}

const noop = () => undefined
function normalizeApi<ExtraApi = void>(
  api: JournalTargetApi<ExtraApi>,
): RequiredJournalTargetApi<ExtraApi> {
  return {
    ...api,

    // HACK: there's no clean way to have a consistent type here. IF the user doesn't provide a `getApi` method and try to use it it will throw an exception
    getApi: api.getApi || (noop as any),
  }
}

export function createJournalTarget<
  Options extends PartialJournalTargetOptions = PartialJournalTargetOptions,
  ExtraApi = any,
>(
  journalName: string,
  createApi: CreateJournalTargetApi<Options, ExtraApi>,
  defaultOptions: Options,
): CreateJournalTargetCreator<Options, ExtraApi> {
  return (initialOptions: Partial<Options> = {}): CreateJournalTarget<ExtraApi> => {
    const options: RequiredJournalTargetOptions<Options> = {
      ...defaultJournalTargetOptions,
      ...defaultOptions,
      ...initialOptions,
    }

    return async (journalOptions: JournalOptions<ExtraApi>) => {
      const originalApi = await createApi(options, journalOptions)
      const api = normalizeApi<ExtraApi>(originalApi)

      return Promise.resolve<JournalTargetComposer<ExtraApi>>({
        name: journalName,

        addEntry: next => async entryInfo => {
          if (options.disabled) {
            return next(entryInfo)
          }

          // TODO: evaluate to make skip and block async
          const transformedEntryInfo = options.transformEntry(entryInfo)
          const skip = options.skipEntry(transformedEntryInfo)
          const block = options.blockEntry(transformedEntryInfo)

          if (!skip) {
            await api.addEntry(transformedEntryInfo)
          }

          if (!block) {
            return next(transformedEntryInfo)
          }
        },

        getEntries: next => () => {
          if (api.getEntries) {
            return api.getEntries()
          }
          return next()
        },

        clearEntries: next => async () => {
          if (api.clearEntries) {
            await api.clearEntries()
          }
          return next()
        },

        getApi: () => {
          return api.getApi()
        },
      })
    }
  }
}
