export const generateVehiclesOptions = (
  isSimulation: boolean = false,
): uui.domain.ui.list.ListOptions => {
  return {
    search: {
      query: '',
      fields: ['displayName'],
      operator: 'or',
      exactMatch: false,
    },
    filter: [],
    sort: [
      {
        // The default sorting is by displayName for simulation and by idx for real data.
        field: isSimulation ? 'displayName' : 'ui.sort.idx',
        direction: 'asc',
      },
    ],
    group: [],
  }
}
