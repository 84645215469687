import it from 'date-fns/locale/it'
import de from 'date-fns/locale/de'
import fr from 'date-fns/locale/fr'
import es from 'date-fns/locale/es'
import nl from 'date-fns/locale/nl'
import pl from 'date-fns/locale/pl'
import pt from 'date-fns/locale/pt'
import en from 'date-fns/locale/en-US'

export const createDateFnsOptions = (
  userConfig: uui.domain.UserConfiguration,
): uui.domain.DateFnsOptions => {
  const { language, weekStartsOn } = userConfig
  const start = weekStartsOnMap[weekStartsOn || 'su']

  switch (language) {
    case 'it':
      return { locale: it, weekStartsOn: start }
    case 'de':
      return { locale: de, weekStartsOn: start }
    case 'es':
      return { locale: es, weekStartsOn: start }
    case 'fr':
      return { locale: fr, weekStartsOn: start }
    case 'nl':
      return { locale: nl, weekStartsOn: start }
    case 'pl':
      return { locale: pl, weekStartsOn: start }
    case 'pt':
      return { locale: pt, weekStartsOn: start }
    default:
      return { locale: en, weekStartsOn: start }
  }
}

const weekStartsOnMap: Record<uui.domain.WeekStartsOn, uui.domain.WeeklyValidSelection> = {
  su: 0,
  mo: 1,
  tu: 2,
  we: 3,
  th: 4,
  fr: 5,
  sa: 6,
}
