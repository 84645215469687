import type { Environment } from './getSentryEnvironment'

export function getSentryConfig(sentryEnvironment: Environment) {
  switch (sentryEnvironment) {
    case 'qa':
    case 'dev':
    case 'local':
    case 'test-a':
    case 'test-b':
    case 'unknown':
    case 'preview':
    case 'production':
    default:
      return {
        dsn: 'https://c98f4e71112f42b5967d033ccc428b77@o572504.ingest.sentry.io/5729009',
        sampleRate: 1.0,
        tracesSampleRate: 1.0,
        maxBreadcrumbs: 100,
      }
  }
}
