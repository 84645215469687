import type { PartialJournalTargetOptions as PendoTargetOptions } from '@/journal'

import {
  AddEntryInfo,
  JournalOptions,
  createJournalTarget,
  CreateJournalTargetApi,
  CreateJournalTargetCreator,
} from '@/journal'

const defaultOptions: PendoTargetOptions = {
  disabled: false,
  skipEntry: (entry: AddEntryInfo) => !entry.entry.tags.includes('pendo'),
}

export const createPendoTargetApi: CreateJournalTargetApi<PendoTargetOptions, void> = (
  _options: PendoTargetOptions,
  journalOptions: JournalOptions<void>,
) => {
  return Promise.resolve({
    addEntry: (entryInfo: AddEntryInfo) => {
      const { entry } = entryInfo

      const pendo: uui.domain.pendo.Pendo | undefined = globalThis.pendo
      if (!pendo) {
        return Promise.resolve()
      }

      const value = entry.value.replace(journalOptions.prefix, '').trim()
      pendo.track(value, entry.info)

      return Promise.resolve()
    },
  })
}

export function createPendoTarget(): CreateJournalTargetCreator<PendoTargetOptions> {
  return createJournalTarget<PendoTargetOptions>('pendo', createPendoTargetApi, defaultOptions)
}
