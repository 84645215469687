export const generateDriverAssignmentsOptions = (): uui.domain.ui.list.ListOptions => {
  return {
    search: {
      query: '',
      fields: ['recurrence.label'],
      operator: 'and',
      exactMatch: false,
    },
    filter: [
      {
        field: 'ui.filter.recurrenceType',
        values: ['ANNUAL', 'BEFORE', 'DATE', 'MONTHLY', 'TODAY', 'WEEKLY', 'any'],
        operator: 'or',
      },
    ],
    sort: [
      {
        field: 'ui.sort.recurrenceType',
        direction: 'asc',
      },
      {
        field: 'ui.sort.value',
        direction: 'asc',
      },
    ],
    group: [],
  }
}
