import { produce } from 'immer'
import * as accountHelpers from './accountFormats'

export function normalizeRmAccount(
  account: uui.domain.client.rm.Account,
  user: uui.domain.client.rm.RouteManagerUser,
) {
  return produce(account, draft => {
    // Normalize account preferences
    const preferences = draft.preferences
    preferences.dateFormat = user.settings.dateFormat
    preferences.currency = user.settings.currency
    preferences.timeFormat = user.settings.timeFormat
    preferences.distanceFormat = accountHelpers.convertAccountDistanceFormat(
      user.settings.distanceFormat,
    )

    // Normalize account details
    const details = draft.details
    details.name = user.name
    details.surname = user.surname
    details.email = user.email
  })
}
