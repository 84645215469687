export const generateTrackingOptions = (): uui.domain.ui.list.ListOptions => {
  return {
    search: {
      query: '',
      fields: ['name', 'tags', 'badges', 'gpsDeviceId'],
      operator: 'or',
      exactMatch: false,
    },
    filter: [
      {
        field: 'filter.badges',
        operator: 'or',
        values: [],
      },
      {
        field: 'filter.includeDevicesWithNoBadges',
        operator: 'or',
        values: [],
      },
    ],
    sort: [
      {
        field: 'ui.sort.lastActivity',
        direction: 'asc',
      },
      {
        field: 'name',
        direction: 'asc',
      },
    ],
    group: [],
  }
}
