import axios from 'axios'
import { IntlMessageFormat } from 'intl-messageformat'

type Messages = Record<string, string>

export type Intl = {
  translate: uui.domain.intl.Translate
  changeLanguage: (language: uui.domain.Language) => Promise<void>
  /**
   * Allows waiting until the messages are loaded.
   * It resolves immediately if the messages are available.
   */
  ready: () => Promise<void>
}

/**
 * Create a new Intl instance and automatically starts loading of the messages.
 */
export const createIntl = async (language: uui.domain.Language): Promise<Intl> => {
  return new Promise(async (resolve, reject) => {
    try {
      const messages: Record<string, string> = {}
      const state = {
        language,
        loading: loadIntlMessagesRequest(language),
        messages,
      }

      const changeLanguage = async (language: uui.domain.Language) => {
        if (state.language === language) {
          // we don't plan to allow concurrent changes of language.
          // The consumer should always wait the resolution of the Promise.
          return state.loading.then(() => undefined)
        }

        try {
          state.loading = loadIntlMessagesRequest(language)
          state.messages = await state.loading
          state.language = language
        } catch (e) {
          throw new Error(`Impossible to load the requested language: ${language}`)
        }
      }

      const ready = async () => state.loading.then(() => undefined)

      const translate = (options: uui.domain.intl.FormattedMessage) => {
        const message = state.messages[options.id]

        if (message === undefined) {
          if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
            return options.id
          } else {
            throw new Error(`Trying top translate an unknown key: ${options.id}`)
          }
        }

        const translation = new IntlMessageFormat(message, language).format(options.values)

        if (translation !== undefined && typeof translation !== 'string') {
          throw new Error(
            `Translation arrays are not managed, params: options: ${options}, locale: ${language}, translation: ${translation}`,
          )
        }

        // ATTENTION: the character `_` is used as a constant value to explicitly define an
        // untranslated string that should fallback to the translation KEY
        return translation === '_' ? options.id : translation ? translation : options.id
      }

      state.messages = await state.loading

      resolve({
        ready,
        changeLanguage,
        translate,
      })
    } catch (e) {
      reject()
    }
  })
}

const loadIntlMessagesRequest = (locale: uui.domain.Language): Promise<Messages> => {
  return new Promise(async (resolve, reject) => {
    const appVersion = process.env.appVersion

    try {
      const response = await axios.get<Messages>(`/locales/${locale}.json?v=${appVersion}`)
      if (response.status !== 200) {
        return reject(response)
      }

      resolve(response.data)
    } catch (e) {
      reject(e)
    }
  })
}
