const months: number[] = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

export const isValidStringDate = (dateString: string, separator?: string): boolean => {
  const sep = separator ? `\\${separator}` : ''
  const regex = new RegExp(`^(\\d{4})${sep}{0,1}(\\d{2})${sep}{0,1}(\\d{2})$`)

  const groups = dateString.match(regex)

  if (!groups) return false

  const year = parseInt(groups[1], 10)
  const month = parseInt(groups[2], 10)
  const day = parseInt(groups[3], 10)

  if (month === 0 || month > 12) {
    return false
  }

  // leap years
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
    months[1] = 29
  }

  return day > 0 && day <= months[month - 1]
}

export const getClosestHour = (time: number, next: boolean = true, silent?: boolean): number => {
  const timeHours: number = time / 3600

  if (!Number.isFinite(timeHours) || timeHours < 0 || timeHours > 24) {
    if (!silent) {
      console.error('Invalid given time value: [%o]', time)
    }
  }

  const hours: number = next ? Math.ceil(timeHours) : Math.floor(timeHours)
  return hours * 3600
}
