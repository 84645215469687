export const generateRoadSegmentsOptions = (): uui.domain.ui.list.ListOptions => {
  return {
    search: {
      query: '',
      fields: ['name'],
      operator: 'or',
      exactMatch: false,
    },
    filter: [
      {
        field: 'type',
        values: ['blocked', 'delayed', 'disabled'],
        operator: 'or',
      },
    ],
    sort: [
      {
        field: 'name',
        direction: 'asc',
      },
    ],
    group: [],
  }
}
