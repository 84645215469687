import type { AddEntryInfo } from '@/journal'

export function createSentryException(entryInfo: AddEntryInfo) {
  const sentryException = new Error()

  sentryException.name = entryInfo.entry.value
  sentryException.stack = entryInfo.entry.info?.stack
  sentryException.message = entryInfo.entry.info?.message

  return sentryException
}
