import {
  JournalOptions,
  JournalEntry,
  AddJournalEntryParams,
  CreateJournal,
  JournalTarget,
} from './typings'
import { composeMiddlewares } from './utils/composeMiddlewares'
import { createEntry } from './utils/createEntry'
import { getEntrySize } from './utils/getEntrySize'
import { initializeJournalTargets } from './utils/initializeJournalTargets'
import { createInMemoryTarget } from './targets/inMemory/createInMemoryTarget'
import { createConsoleTarget } from './targets/console/createConsoleTarget'

const defaultOptions: JournalOptions = {
  name: 'journal',
  prefix: '',
  level: 'normal',
  targets: [createInMemoryTarget(), createConsoleTarget()],
  disabled: false,
}

const addEntry = async (
  options: JournalOptions,
  middleware: JournalTarget,
  severity: JournalEntry['severity'],
  value: string,
  params?: AddJournalEntryParams,
) => {
  try {
    if (options.disabled) {
      return
    }

    const entry = createEntry(options, severity, value, params)
    const size = getEntrySize(entry)
    await middleware.addEntry({ entry, size })
  } catch (e) {
    console.error('[Journal.addEntry] Failed.', e)
  }
}

export const createJournal: CreateJournal = async (
  initialOptions: Partial<JournalOptions> = {},
) => {
  const options = { ...defaultOptions, ...initialOptions }

  const targets = await initializeJournalTargets(options, options.targets)
  const targetsMap = targets.reduce((map, target) => map.set(target.name, target), new Map())
  const middleware = composeMiddlewares(targets)

  return {
    get options() {
      return options
    },

    get outputLevel(): JournalOptions['level'] {
      return options.level
    },
    set outputLevel(value: JournalOptions['level']) {
      options.level = value
    },

    get prefix(): JournalOptions['prefix'] {
      return options.prefix
    },
    set prefix(value: JournalOptions['prefix']) {
      options.prefix = value
    },

    get disabled(): JournalOptions['disabled'] {
      return options.disabled
    },
    set disabled(value: JournalOptions['disabled']) {
      options.disabled = value
    },

    clearEntries: async () => {
      try {
        return await middleware.clearEntries()
      } catch (e) {
        console.error('[Journal.clearEntries] Failed.', e)
      }
    },

    getEntries: async () => {
      try {
        return await middleware.getEntries()
      } catch (e) {
        console.error('[Journal.getEntries] Failed.', e)
        return []
      }
    },

    addEntry: async (value: string, params?: AddJournalEntryParams) => {
      await addEntry(options, middleware, 'log', value, params)
    },
    addWarning: async (value: string, params?: AddJournalEntryParams) => {
      await addEntry(options, middleware, 'warn', value, params)
    },
    addError: async (value: string, params?: AddJournalEntryParams) => {
      await addEntry(options, middleware, 'error', value, params)
    },

    getTarget: name => {
      try {
        return targetsMap.get(name)
      } catch (e) {
        console.error('[Journal.getTarget] Failed.', e)
      }
    },
  }
}
