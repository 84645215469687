import { getMapStylesCache } from './cache'

const modeOn = 'on' as const
const modeOff = 'off' as const

export const getDefaultMapStyles = (gpsOnly: boolean): uui.domain.ui.map.markers.MapStyles => {
  // ------------------------------------------------
  // Default initial Object
  // ------------------------------------------------

  const mapStyles: uui.domain.ui.map.markers.MapStyles = {
    depot: { mode: modeOn, custom: {} },
    order: { mode: modeOn, custom: {} },
    routePolyline: { mode: modeOff, custom: {} },
    region: { mode: modeOff, custom: {} },
    trafficProfile: { mode: modeOff, custom: {} },
    roadSegment: { mode: modeOff, custom: {} },

    geofence: { mode: modeOff, custom: {} },
    breadcrumb: { mode: modeOff, custom: {} },
    deviceEvent: { mode: modeOff, custom: {} },

    place: { mode: gpsOnly ? modeOn : modeOff, custom: {} },
    device: { mode: gpsOnly ? 'label' : modeOff, custom: {} },
    vehicle: { mode: gpsOnly ? 'label' : modeOff, custom: {} },
  }

  // ------------------------------------------------
  // Restore from local-storage
  // ------------------------------------------------

  const cache = getMapStylesCache()

  for (const [key, value] of Object.entries(cache)) {
    if (value && mapStyles.hasOwnProperty(key)) {
      mapStyles[key] = mapStyles[key].mode = value
    }
  }

  // ------------------------------------------------

  return mapStyles
}
