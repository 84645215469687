export function getDeviceEventName(eventType: uui.domain.client.gps.wwgps.EventInfoType) {
  switch (eventType) {
    case 'ignition-on':
      return 'Ignition On'

    case 'ignition-off':
      return 'Ignition Off'

    case 'idle-start':
      return 'Idle Start'

    case 'idle-update':
      return 'Idle Update'

    case 'idle-stop':
      return 'Idle End'

    case 'rapid-acceleration':
      return 'Rapid Acceleration'

    case 'rapid-deceleration':
      return 'Rapid Deceleration'

    case 'engine-warning-light':
      return 'Engine Warning Light'

    case 'device-power-reset':
      return 'Device Power Reset'

    case 'user-button-press':
      return 'User Button Press'

    case 'battery-level-report':
      return 'Battery Level Report'

    case 'obd-port-plug-in':
      return 'OBD Port Plug-in'

    case 'obd-error':
      return 'OBD Error'

    case 'device-unplug':
      return 'Device Unplug'

    case 'digital-input-1-low':
      return 'Digital Input 1 Low'

    case 'digital-input-1-high':
      return 'Digital Input 1 High'

    case 'digital-input-2-low':
      return 'Digital Input 2 Low'

    case 'digital-input-2-high':
      return 'Digital Input 2 High'

    case 'digital-input-3-low':
      return 'Digital Input 3 Low'

    case 'digital-input-3-high':
      return 'Digital Input 3 High'

    case 'digital-input-4-low':
      return 'Digital Input 4 Low'

    case 'digital-input-4-high':
      return 'Digital Input 4 High'

    case 'digital-input-5-low':
      return 'Digital Input 5 Low'

    case 'digital-input-5-high':
      return 'Digital Input 5 High'

    case 'garmin-enabled':
      return 'Garmin Enabled'

    case 'garmin-disabled':
      return 'Garmin Disabled'

    case 'hard-braking':
      return 'Hard Braking'

    case 'harsh-braking':
      return 'Harsh Braking'

    case 'severe-braking':
      return 'Severe Braking'

    case 'hard-acceleration':
      return 'Hard Acceleration'

    case 'harsh-acceleration':
      return 'Harsh Acceleration'

    case 'severe-acceleration':
      return 'Severe Acceleration'

    case 'hard-cornering':
      return 'Hard Cornering'

    case 'device-speed-alert':
      return 'Device Speed Alert'

    case 'max-speed-threshold':
      return 'Max Speed Threshold'

    case 'moving-outside-hours':
      return 'Moving After Hours'

    case 'geofence-trigger':
      return 'Geo-Alert Trigger'

    case 'dtc-found':
      return 'DTC Found'

    case 'vehicle-battery-low':
      return 'Vehicle Battery Low'
  }
}
