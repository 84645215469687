import { AddEntryInfo, JournalEntry, JournalEntryKeys } from '../../typings'

export const reduceEntrySize = (entryInfo: AddEntryInfo, maxSizeKb: number): JournalEntry => {
  const { entry, size } = entryInfo

  const clone: JournalEntry = { ...entry }
  let entrySizeKb = size.total / 1024

  type FieldsBySize = { key: JournalEntryKeys; value: number }
  const fieldsBySize: FieldsBySize[] = Object.entries(size.keys)
    .map(([key, value = 0]) => ({ key: key as JournalEntryKeys, value }))
    .sort(({ value: valueA }, { value: valueB }) => valueB - valueA)

  reduceSize: for (const { key, value } of fieldsBySize) {
    entrySizeKb -= value

    switch (key) {
      case 'info':
        clone[key] = 'Additional data too big to be stored.'
        break

      case 'value':
        clone[key] = `${clone[key].slice(0, 100)}...`
        break

      case 'tags':
        delete clone[key as any]
        break
    }

    if (entrySizeKb < maxSizeKb) {
      break reduceSize
    }
  }

  return clone
}
