export const generateTrafficAreasOptions = (): uui.domain.ui.list.ListOptions => {
  return {
    search: {
      query: '',
      fields: [],
      operator: 'or',
      exactMatch: false,
    },
    filter: [
      {
        field: 'recurrence',
        // empty until the user select a traffic profile
        // then an action will set the correct value
        values: [],
        operator: 'and',
      },
    ],
    sort: [
      {
        field: 'name',
        direction: 'asc',
      },
    ],
    group: [],
  }
}
