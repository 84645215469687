import {
  AddEntryInfo,
  JournalOptions,
  CreateJournalTargetCreator,
  CreateJournalTargetApi,
  JournalEntry,
} from '../../typings'
import { createJournalTarget } from '../helpers/createJournalTarget'

import { ConsoleTargetOptions, ConsoleTargetApi } from './typings'

const defaultConsoleOptions: ConsoleTargetOptions = {
  consoleOutput: 'normal',
  disabled: false,
}

const logStyle = `
font-size: 12px;
display: block;
`

const errorStyle = `
background: rgba(42, 0, 0, 1);
color: rgba(242, 116, 121, 1);
font-size: 14px;
font-weight: bold;
display: block;
`

const warnStyle = `
background: rgba(52, 43, 5, 1);
color: rgba(251, 216, 160, 1);
font-size: 12px;
font-weight: bold;
display: block;
`

const tagsStyle = `
color: khaki;
font-style: italic;
display: block;
`

const keyStyle = `
color: rgba(212, 120, 230, 1);
font-size: 12px;
font-weight: bold;
display: block;
`

const stringValueStyle = `
color: rgba(229, 143, 95, 1);
font-size: 12px;
display: block;
`

const numberValueStyle = `
color: rgba(146, 132, 244, 1);
font-size: 12px;
display: block;
`

const logEntry = (entry: JournalEntry, log: typeof console.log) =>
  Object.keys(entry).forEach(key => {
    const value = entry[key]
    const parsedValue = Array.isArray(value)
      ? `[${value}]`
      : key === 'info'
      ? typeof value === 'string'
        ? JSON.parse(value)
        : value
      : key === 'timestamp'
      ? new Date(value).toString()
      : `${value}`
    const valueStyle = typeof value === 'number' ? numberValueStyle : stringValueStyle

    typeof parsedValue === 'string'
      ? log(`%c${key}: %c${parsedValue}`, keyStyle, valueStyle)
      : log(`%c${key}:`, keyStyle, parsedValue)
  })

export const createConsoleTargetApi: CreateJournalTargetApi<
  ConsoleTargetOptions,
  ConsoleTargetApi
> = (_options: ConsoleTargetOptions, journalOptions: JournalOptions<ConsoleTargetApi>) =>
  Promise.resolve({
    addEntry: (entryInfo: AddEntryInfo) => {
      const { entry } = entryInfo

      const canPrint =
        journalOptions.level === 'verbose' ||
        (journalOptions.level === 'normal' && entry.severity !== 'log')

      if (canPrint) {
        switch (entry.severity) {
          case 'log':
            console.groupCollapsed(`%c${entry.value} %c{${entry.tags}}`, logStyle, tagsStyle)
            logEntry(entry, console.log)
            console.log(entry)
            console.groupEnd()
            break

          case 'warn':
            console.groupCollapsed(`%c${entry.value} %c{${entry.tags}}`, warnStyle, tagsStyle)
            logEntry(entry, console.warn)
            console.warn(entry)
            console.groupEnd()
            break

          case 'error':
            console.groupCollapsed(`%c${entry.value} %c{${entry.tags}}`, errorStyle, tagsStyle)
            logEntry(entry, console.error)
            console.error(entry)
            console.groupEnd()
            break
        }
      }

      return Promise.resolve()
    },
  })

export const createConsoleTarget: CreateJournalTargetCreator<
  ConsoleTargetOptions,
  ConsoleTargetApi
> = createJournalTarget<ConsoleTargetOptions, ConsoleTargetApi>(
  'console',
  createConsoleTargetApi,
  defaultConsoleOptions,
)
