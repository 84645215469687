const sanitize = (str: string = ''): string => str.replace(/\s/g, '')

const parseMeridiem = (str: string): string | undefined => {
  if (str?.match(meridiemRegex)) {
    return str.toLowerCase().includes('a') ? 'am' : 'pm'
  }
}

const getHours = (hoursString: string, meridiem: string | undefined): number => {
  const hours: number = parseInt(hoursString)

  if (hours === 12 && !!meridiem) {
    return meridiem === 'am' ? 0 : 12
  }

  return !!meridiem && meridiem === 'pm' ? hours + 12 : hours
}

// Currently supported time formats
// https://github.com/WorkWave/via-volo/blob/d7c083ece62a68bc0f82305795097b0be3f453dc/client/volo/util/time.js#L201-L203
// "H:m:s", "H.m.s", "H:m:sa", "H:m:sA"
const meridiemRegex: RegExp = new RegExp(`(\\s*[ap](?:.m.|m))`, 'i')
const timeRegex: RegExp = new RegExp(
  `^([01]\\d|2[0-3]|\\d)(?:[:.])?([0-5]?\\d)?(?:[:.])?([0-5]\\d|[0-9])?${meridiemRegex.source}?$`,
  'i',
)

// ------------------------------------------------------------------------------
// ------------------------------------------------------------------------------

type ParsedTime = {
  time?: {
    hours: number
    minutes: number
    seconds: number
  }
  error?: string
}

export const parseTimeString = (str: string): ParsedTime => {
  if (str.length === 0) {
    return { error: 'Cannot parse empty string' }
  }

  const result: RegExpExecArray | null = timeRegex.exec(sanitize(str))

  if (!result) {
    return { error: 'Unsupported format' }
  }

  const [, h, m, s, mrd] = result
  const meridiem: string | undefined = parseMeridiem(mrd)

  const minutes: number = m ? parseInt(m) : 0
  const seconds: number = s ? parseInt(s) : 0
  const hours: number = getHours(h, meridiem)

  return {
    time: {
      seconds,
      minutes,
      hours,
    },
  }
}
