import {
  AddEntryInfo,
  JournalOptions,
  CreateJournalTargetCreator,
  CreateJournalTargetApi,
} from '../../typings'
import { createJournalTarget } from '../helpers/createJournalTarget'

import { LocalStorageTargetOptions, LocalStorageTargetApi } from './typings'
import { addEntry } from './addEntry'

const separator = '@||@'

const defaultInMemoryOptions: LocalStorageTargetOptions = {
  maxLocalStorageSize: 2048,
  disabled: false,
}

export const createLocalStorageTargetApi: CreateJournalTargetApi<
  LocalStorageTargetOptions,
  LocalStorageTargetApi
> = (options: LocalStorageTargetOptions, journalOptions: JournalOptions) => {
  return Promise.resolve({
    addEntry: (entryInfo: AddEntryInfo) => {
      const { entry } = entryInfo

      const canPrint =
        journalOptions.level === 'verbose' ||
        (journalOptions.level === 'normal' && entry.severity !== 'log')

      if (canPrint && options && journalOptions) {
        addEntry(separator, options, journalOptions, entryInfo)
      }

      return Promise.resolve()
    },

    getEntries: () => {
      const storedString = localStorage.getItem(journalOptions.name)

      if (!storedString) return Promise.resolve([])

      const storedEntries = storedString.split(separator).map(entry => JSON.parse(entry))

      return Promise.resolve(storedEntries)
    },

    clearEntries: () => {
      localStorage.setItem(journalOptions.name, '')

      return Promise.resolve()
    },

    // getApi: () => undefined,
  })
}

export const createLocalStorageTarget: CreateJournalTargetCreator<
  LocalStorageTargetOptions,
  LocalStorageTargetApi
> = createJournalTarget<LocalStorageTargetOptions, LocalStorageTargetApi>(
  'local-storage',
  createLocalStorageTargetApi,
  defaultInMemoryOptions,
)
