import * as routeHelpers from '../route'

export const composeSchedulerLoads = (
  orders:
    | Record<string, uui.domain.client.rm.Order>
    | Record<string, uui.domain.client.rm.ExtendedOrder>, // Approved Orders doesn't have ExtendedOrders
  steps: uui.domain.client.rm.RouteStep[] = [],
  calendarizedSettings: uui.domain.server.rm.VehicleSettings,
): uui.domain.client.rm.SchedulerRowData['loads'] => {
  let higherSaturationId: string = ''
  const ordersLoads: Record<string, number> = {}
  const saturation: Record<string, number> = {}
  const vehicleLoads: Record<string, number> = {}

  // Extract loads limit supported by the vehicle
  if (calendarizedSettings) {
    Object.entries(calendarizedSettings.loadCapacities).reduce(
      (acc: Record<string, number>, [loadName, loadValue]) => {
        acc[loadName] = (acc[loadName] || 0) + loadValue / 100
        return acc
      },
      vehicleLoads,
    )
  }

  function getOrder(orderId: string) {
    const order = orders[orderId]
    if ('order' in order) {
      return order.order
    }
    return order
  }

  // Initialize the starting value for each load type
  // All delivery only steps should be included in the initial value

  const pickupOrders = new Set<string>()
  const deliveryOnlyIndex = new Set<number>()

  for (let index = 0; index < steps.length; index++) {
    const step = steps[index]

    if (routeHelpers.isOrderRelated(step) && step.ref) {
      if (step.type === 'pickup') {
        pickupOrders.add(step.ref)
      } else if (step.type === 'delivery' && !pickupOrders.has(step.ref)) {
        deliveryOnlyIndex.add(index)
      }
    }
  }

  for (const index of deliveryOnlyIndex) {
    const step = steps[index]

    if (step.ref) {
      const order = getOrder(step.ref)

      if (order?.loads) {
        Object.entries(order.loads).reduce((loadAcc, [key, value]) => {
          const orderStep = getOrderStep(order, step.type)
          const executedValue = orderStep.trackingData?.loadTrackedMap?.[key]?.value

          // values are sent as decimal values, we have to divide them by 100
          const loadValue = executedValue !== undefined ? executedValue / 100 : value / 100

          loadAcc[key] = (loadAcc[key] || 0) + loadValue

          return loadAcc
        }, ordersLoads)
      }
    }
  }

  // Find the max saturation for every kind of load
  let loads = { ...ordersLoads }

  for (const step of steps) {
    if (routeHelpers.isOrderRelated(step) && step.ref) {
      const order = getOrder(step.ref)
      const modifier = step.type === 'pickup' ? 1 : -1

      if (order?.loads) {
        loads = Object.entries(order.loads).reduce((loadAcc, [key, value]) => {
          const orderStep = getOrderStep(order, step.type)
          const executedValue = orderStep.trackingData?.loadTrackedMap?.[key]?.value

          // values are sent as decimal values, we have to divide them by 100
          const loadValue =
            (executedValue !== undefined ? executedValue / 100 : value / 100) * modifier

          loadAcc[key] = Math.max((loadAcc[key] || 0) + loadValue, 0)

          const max = ordersLoads[key] || 0
          ordersLoads[key] = Math.max(max, loadAcc[key])

          return loadAcc
        }, loads)
      }
    }
  }

  Object.entries(vehicleLoads).reduce(
    (saturationAcc: Record<string, number>, [loadName, loadCapacity]) => {
      const usedLoads = ordersLoads[loadName]

      if (typeof usedLoads === 'number') {
        saturationAcc[loadName] = parseFloat(((usedLoads / loadCapacity) * 100).toFixed(2))

        if (!higherSaturationId || saturationAcc[loadName] > saturationAcc[higherSaturationId]) {
          higherSaturationId = loadName
        }
      } else if (typeof saturationAcc[loadName] !== 'number') {
        saturationAcc[loadName] = 0
      }

      return saturationAcc
    },
    saturation,
  )

  return {
    vehicle: vehicleLoads,
    orders: ordersLoads,
    saturation,
    higherSaturationId,
  }
}

function getOrderStep(
  order: uui.domain.client.rm.Order,
  routeStepType: uui.domain.client.rm.RouteStep['type'],
) {
  switch (order.type) {
    case 'd':
    case 's':
      return order.delivery

    case 'p':
      return order.pickup

    case 'pd':
      return routeStepType === 'pickup' ? order.pickup : order.delivery
  }
}
