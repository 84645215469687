export const generateTrafficProfilesOptions = (): uui.domain.ui.list.ListOptions => {
  return {
    search: {
      query: '',
      fields: ['ui.sort.label'],
      operator: 'or',
      exactMatch: false,
    },
    filter: [],
    sort: [
      {
        field: 'ui.sort.type',
        direction: 'asc',
      },
    ],
    group: [],
  }
}
