export const generateOrderStepsOptions = (): uui.domain.ui.list.ListOptions => {
  return {
    search: {
      query: '',
      fields: [
        'id',
        'pairedId',
        'order.name',
        'stepNumber',
        'routeStepLabel',
        'location.address',
        'location.geoAddress',
        'vehicleName',
        'barcodes',
        'status',
        'formattedData.status',
        'formattedData.address',
        'formattedData.orderId',
        'formattedData.requiredVehicle',
        'formattedData.eligibility',
        'formattedData.orderType',
        'formattedData.serviceTime',
        'formattedData.status',
        'formattedData.assignedTo',
        'formattedData.addressCheck',
        'formattedData.requiredTags',
        'formattedData.bannedTags',
        'formattedData.idleTime',
        'formattedData.notes',
        'formattedData.timeWindow1',
        'formattedData.timeWindow2',
        'formattedData.phone',
        'formattedData.email',
        'formattedData.barcodes',
        'formattedData.shopifyOrderName',
        'formattedData.pairId',
        'formattedData.priority',
        'formattedData.scheduleTime',
        'formattedData.company',

        // For orders we have to check EVERY custom fields when searching.
        'formattedData.customFields',
      ],
      operator: 'or',
      exactMatch: false,
    },
    filter: [
      {
        field: 'isUnassigned',
        values: [true, false],
        operator: 'or',
      },
      {
        field: 'status',
        values: ['completed', 'notCompleted', 'toDo', 'unassigned', 'undeclared'],
        operator: 'or',
      },
      {
        field: 'violated',
        values: [false, true],
        operator: 'or',
      },
      {
        field: 'expiring',
        values: [false, true],
        operator: 'or',
      },
    ],
    sort: [
      {
        field: 'ui.sort.eta',
        direction: 'asc',
      },
    ],
    group: [
      {
        field: 'ui.sort.route',
        direction: 'asc',
      },
    ],
  }
}
