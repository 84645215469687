/* eslint-disable @typescript-eslint/prefer-ts-expect-error */ // enables @ts-ignore in this file. TODO: fixing TS reporting "The 'import.meta' meta-property is only allowed when the '--module' option is 'es2020', 'esnext', or 'system'."

export function getEnvVariables() {
  return {
    appVersion: process.env.appVersion,

    // @ts-ignore
    DISABLE_SENTRY: process.env.PUBLIC_DISABLE_SENTRY === 'true',
  }
}
