import {
  AddEntryInfo,
  JournalOptions,
  JournalEntry,
  CreateJournalTargetCreator,
  CreateJournalTargetApi,
} from '../../typings'
import { createJournalTarget } from '../helpers/createJournalTarget'

import { InMemoryTargetOptions, InMemoryTargetApi } from './typings'

const defaultInMemoryOptions: InMemoryTargetOptions = {
  disabled: false,
}

export const createInMemoryTargetApi: CreateJournalTargetApi<
  InMemoryTargetOptions,
  InMemoryTargetApi
> = (_options: InMemoryTargetOptions, journalOptions: JournalOptions) => {
  let entries: JournalEntry[] = []

  return Promise.resolve({
    addEntry: (entryInfo: AddEntryInfo) => {
      const { entry } = entryInfo

      const canPrint =
        journalOptions.level === 'verbose' ||
        (journalOptions.level === 'normal' && entry.severity !== 'log')

      if (canPrint) {
        entries.push(entry)
      }

      return Promise.resolve()
    },

    getEntries: () => Promise.resolve([...entries]),

    clearEntries: () => {
      entries = []

      return Promise.resolve()
    },

    // getApi: () => undefined,
  })
}

export const createInMemoryTarget: CreateJournalTargetCreator<
  InMemoryTargetOptions,
  InMemoryTargetApi
> = createJournalTarget<InMemoryTargetOptions, InMemoryTargetApi>(
  'in-memory',
  createInMemoryTargetApi,
  defaultInMemoryOptions,
)
