export const generateOrdersGridOptions = (): uui.domain.ui.list.ListOptions => {
  return {
    search: {
      query: '',
      fields: [
        'id',
        'barcodes',
        'pairedId',
        'order.name',
        'stepNumber',
        'routeStepLabel',
        'location.address',
        'location.geoAddress',
        'vehicleName',
        'formattedData.status',
        'formattedData.address',
        'formattedData.orderId',
        'formattedData.requiredVehicle',
        'formattedData.eligibility',
        'formattedData.orderType',
        'formattedData.serviceTime',
        'formattedData.status',
        'formattedData.assignedTo',
        'formattedData.addressCheck',
        'formattedData.requiredTags',
        'formattedData.bannedTags',
        'formattedData.idleTime',
        'formattedData.notes',
        'formattedData.stopNo',
        'formattedData.timeWindow1',
        'formattedData.timeWindow2',
        'formattedData.phone',
        'formattedData.email',
        'formattedData.barcodes',
        'formattedData.shopifyOrderName',
        'formattedData.pairId',
        'formattedData.priority',
        'formattedData.scheduleTime',
        'formattedData.name',
        'formattedData.company',
        'formattedData.plannedTime',

        // For orders we have to check EVERY custom fields when searching.
        'formattedData.customFields',
      ],
      operator: 'or',
      exactMatch: false,
    },
    filter: [],
    sort: [
      {
        field: 'formattedData.name',
        direction: 'asc',
      },
    ],
    // sort: [],
    group: [],
  }
}
