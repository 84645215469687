export function computeExtendedOrderStepStatus(
  transportExtOrderStep: uui.domain.client.rm.TransportExtendedOrderStep,
  orderStep: uui.domain.client.rm.OrderStep,
): uui.domain.client.rm.ExtendedOrderStepStatus {
  if (transportExtOrderStep.isUnassigned) return 'unassigned'

  if (orderStep.trackingData && !orderStep.trackingData.isEmpty) {
    switch (orderStep.trackingData.status) {
      case 'done':
        return 'completed'

      case 'missing':
        return 'undeclared'

      case 'reschedule':
        return 'notCompleted'
    }
  }

  return 'toDo'
}
