import { JournalOptions, JournalEntry, AddJournalEntryParams } from '../typings'
import { createUuid } from './uuid'

const emptyTags = []

export const createEntry = (
  options: JournalOptions,
  severity: JournalEntry['severity'],
  value: JournalEntry['value'],
  params: AddJournalEntryParams = {},
): JournalEntry => {
  const { info, prefix, tags = [] } = params

  const pre = prefix ? prefix : options.prefix ? options.prefix : null

  const entry: JournalEntry = {
    uuid: createUuid(),
    severity,
    timestamp: Date.now(),
    value: pre ? `${pre}${value}` : value,
    tags: tags.length > 0 ? [...tags] : emptyTags,
  }

  if (info) {
    if (options.serializeInfo) {
      entry.info = JSON.stringify(info)
    } else {
      entry.info = info
    }
  }

  return entry
}
